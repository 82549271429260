@media print {
	.print-break {
		page-break-before: always;
	}
}

[class^="status-HouseIntro-resultTable-"] tr > td:first-child {
	padding-left: 0;
}

[class^="status-HouseIntro-resultTable-"] tr > td:last-child {
	padding-right: 0;
}
