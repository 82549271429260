.ant-tabs .ant-tabs-left-content {
    width: 100%;
    margin-top: 0 !important;
    overflow: hidden;
    padding-right: 30px;
}

.ant-tabs-vertical.ant-tabs-line {
    width: 100%;
    display: flex;
}