@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,500);
@media print {
	.print-break-after {
		page-break-before: always;
	}
}

.App {
	text-align: center;
}

.App-logo {
	-webkit-animation: App-logo-spin infinite 20s linear;
	        animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.fa {
    position: relative;
}

.fa:before {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.page {
    width: 200mm;
    /* min-height: 297mm; */
    padding: 20mm;
    /* padding-right: 30px !important; */
    margin: 0 auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
    padding: 1cm;
    /* border: 5px red solid; */
    height: 257mm;
    /* outline: 2cm #ffeaea solid; */
}

/* @page {
    size: A4;
    margin: 0;
} */
@media print {
    .page {
        margin: 0;
        padding: 10mm 0;
        border: medium none currentColor;
        border: initial;
        border-radius: 0;
        border-radius: initial;
        width: auto;
        width: initial;
        min-height: 0;
        min-height: initial;
        box-shadow: none;
        box-shadow: initial;
        background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
        background: initial;
        page-break-after: always;
    }

    .page-break-after {
        max-width: 100%;
        flex-basis: 100%;
    }
}
.ant-tabs .ant-tabs-left-content {
    width: 100%;
    margin-top: 0 !important;
    overflow: hidden;
    padding-right: 30px;
}

.ant-tabs-vertical.ant-tabs-line {
    width: 100%;
    display: flex;
}
@media print {
	.print-break {
		page-break-before: always;
	}
}

[class^="status-HouseIntro-resultTable-"] tr > td:first-child {
	padding-left: 0;
}

[class^="status-HouseIntro-resultTable-"] tr > td:last-child {
	padding-right: 0;
}

.flip-card {
    background-color: transparent;
    /* width: 323px; */
    /* height: 300px; */
    width: 100%;
    height: 100%;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    /* overflow-y: scroll; */
}

::-webkit-scrollbar {
    display: none;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.flip-card-front {
    background-color: #fff;
    color: black;
    z-index: 2;
}

.flip-card-back {
    background-color: #fff;
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    z-index: 1;
    overflow-y: scroll;
    height: 250px;
}
@media print {
    .frame-paper {
        padding: 10
    }

    .print-break {
        page-break-before: always;
    }
}
/* .fixWidthofColumn {
    width: 100%;
}

*/

@media only screen and (max-width: 768px) {
    .fixWidthofColumn {
        width: 100%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 959px) {
    .fixWidthofColumn {
        width: 50%;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1099px) {
    .fixWidthofColumn {
        width: 100%;
    }
}

@media only screen and (min-width: 1100px) {
    .fixWidthofColumn {
        width: 50%;
    }
}
/*
VIEW IN FULL SCREEN MODE
FULL SCREEN MODE: http://salehriaz.com/404Page/404.html

DRIBBBLE: https://dribbble.com/shots/4330167-404-Page-Lost-In-Space
*/

@-webkit-keyframes rocket-movement {
    100% {
        -webkit-transform: translate(1200px, -600px);
    }
}

@keyframes rocket-movement {
    100% {
        -webkit-transform: translate(1200px, -600px);
                transform: translate(1200px, -600px);
    }
}

@-webkit-keyframes spin-earth {
    100% {
        -webkit-transform: rotate(-360deg);
        -webkit-transition: -webkit-transform 20s;
        transition: -webkit-transform 20s;
        transition: transform 20s;
        transition: transform 20s, -webkit-transform 20s;
    }
}

@keyframes spin-earth {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        -webkit-transition: -webkit-transform 20s;
        transition: -webkit-transform 20s;
        transition: transform 20s;
        transition: transform 20s, -webkit-transform 20s;
    }
}

@-webkit-keyframes move-astronaut {
    100% {
        -webkit-transform: translate(-160px, -160px);
    }
}

@keyframes move-astronaut {
    100% {
        -webkit-transform: translate(-160px, -160px);
        transform: translate(-160px, -160px);
    }
}

@-webkit-keyframes rotate-astronaut {
    100% {
        -webkit-transform: rotate(-720deg);
    }
}

@keyframes rotate-astronaut {
    100% {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}

@-webkit-keyframes glow-star {
    40% {
        -webkit-opacity: 0.3;
    }

    90%,
    100% {
        -webkit-opacity: 1;
        -webkit-transform: scale(1.2);
    }
}

@keyframes glow-star {
    40% {
        -webkit-opacity: 0.3;
        opacity: 0.3;
    }

    90%,
    100% {
        -webkit-opacity: 1;
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        border-radius: 999999px;
    }
}

.spin-earth-on-hover {

    -webkit-transition: ease 200s !important;

    transition: ease 200s !important;
    -webkit-transform: rotate(-3600deg) !important;
            transform: rotate(-3600deg) !important;
}

/*  */

.bg-purple {
    background: url(http://salehriaz.com/404Page/img/bg_purple.png);
    background-repeat: repeat-x;
    background-size: cover;
    background-position: left top;
    height: 100%;
    overflow: hidden;

}

.custom-navbar {
    padding-top: 15px;
}

.brand-logo {
    margin-left: 25px;
    margin-top: 5px;
    display: inline-block;
}




li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 12px;

    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

li a:hover {
    color: #ffcb39;
}

.btn-request {
    padding: 10px 25px;
    border: 1px solid #FFCB39;
    border-radius: 100px;
    font-weight: 400;
}

.btn-request:hover {
    background-color: #FFCB39;
    color: #fff;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

.btn-go-home {
    position: relative;
    z-index: 200;
    margin: 15px auto;
    width: 100px;
    padding: 10px 15px;
    border: 1px solid #FFCB39;
    border-radius: 100px;
    font-weight: 400;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 11px;

    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.btn-go-home:hover {
    background-color: #FFCB39;
    color: #fff;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

.central-body {
    /*    width: 100%;*/
    padding: 17% 5% 10% 5%;
    text-align: center;
}

.objects img {
    z-index: 90;
    pointer-events: none;
}

.object_rocket {
    z-index: 95;
    position: absolute;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    top: 75%;
    pointer-events: none;
    -webkit-animation: rocket-movement 200s linear infinite both running;
            animation: rocket-movement 200s linear infinite both running;
}

.object_earth {
    position: absolute;
    top: 20%;
    left: 15%;
    z-index: 90;
    /*    animation: spin-earth 100s infinite linear both;*/
}

.object_moon {
    position: absolute;
    top: 12%;
    left: 25%;
    /*
    transform: rotate(0deg);
    transition: transform ease-in 99999999999s;
*/
}

.earth-moon {}

.object_astronaut {
    -webkit-animation: rotate-astronaut 200s infinite linear both alternate;
            animation: rotate-astronaut 200s infinite linear both alternate;
}

.box_astronaut {
    z-index: 110 !important;
    position: absolute;
    top: 60%;
    right: 20%;
    will-change: transform;
    -webkit-animation: move-astronaut 50s infinite linear both alternate;
            animation: move-astronaut 50s infinite linear both alternate;
}

.image-404 {
    position: relative;
    z-index: 100;
    pointer-events: none;
}

.stars {
    background: url(http://salehriaz.com/404Page/img/overlay_stars.svg);
    background-repeat: repeat;
    background-size: contain;
    background-position: left top;
}

.glowing_stars .star {
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    width: 3px;
    height: 3px;
    opacity: 0.3;
    will-change: opacity;
}

.glowing_stars .star:nth-child(1) {
    top: 80%;
    left: 25%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 1s;
            animation: glow-star 2s infinite ease-in-out alternate 1s;
}

.glowing_stars .star:nth-child(2) {
    top: 20%;
    left: 40%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 3s;
            animation: glow-star 2s infinite ease-in-out alternate 3s;
}

.glowing_stars .star:nth-child(3) {
    top: 25%;
    left: 25%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 5s;
            animation: glow-star 2s infinite ease-in-out alternate 5s;
}

.glowing_stars .star:nth-child(4) {
    top: 75%;
    left: 80%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 7s;
            animation: glow-star 2s infinite ease-in-out alternate 7s;
}

.glowing_stars .star:nth-child(5) {
    top: 90%;
    left: 50%;
    -webkit-animation: glow-star 2s infinite ease-in-out alternate 9s;
            animation: glow-star 2s infinite ease-in-out alternate 9s;
}

@media only screen and (max-width: 600px) {
    .navbar-links {
        display: none;
    }

    .custom-navbar {
        text-align: center;
    }

    .brand-logo img {
        width: 120px;
    }

    .box_astronaut {
        top: 70%;
    }

    .central-body {
        padding-top: 25%;
    }
}
