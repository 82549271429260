/* .fixWidthofColumn {
    width: 100%;
}

*/

@media only screen and (max-width: 768px) {
    .fixWidthofColumn {
        width: 100%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 959px) {
    .fixWidthofColumn {
        width: 50%;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1099px) {
    .fixWidthofColumn {
        width: 100%;
    }
}

@media only screen and (min-width: 1100px) {
    .fixWidthofColumn {
        width: 50%;
    }
}