.flip-card {
    background-color: transparent;
    /* width: 323px; */
    /* height: 300px; */
    width: 100%;
    height: 100%;
    perspective: 1000px;
    /* overflow-y: scroll; */
}

::-webkit-scrollbar {
    display: none;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #fff;
    color: black;
    z-index: 2;
}

.flip-card-back {
    background-color: #fff;
    color: white;
    transform: rotateY(180deg);
    z-index: 1;
    overflow-y: scroll;
    height: 250px;
}